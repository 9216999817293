<template>
  <div class="relative" data-testid="landing-page">
    <LandingHeaderBar class="sticky top-0 z-50" data-testid="header-bar" />

    <Hero />

    <InfoSection />

    <LearningSection />

    <TakeActionCard />

    <Footer />
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'

definePage({
  meta: { requiresAuth: false },
  name: 'partnerships-business',
})
</script>
